import React from "react";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import prolo from "../../images/prolo.png";
import Seo from "../../main/Seo";
const ProloTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a", textAlign: "left", p: 5 }}>
      <Seo
        title="ProloTherapy -Oxyfresh"
        description="ProloTherapy "
        name="oxyfresh"
        type="ProloTherapy page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${prolo})`,
          height: 400,
          backgroundSize: "cover",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "rgba(101,165,191)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Prolotherapy
        </Typography>
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#31344b",
          fontWeight: "bold",
          textAlign: "center",
          pb: 5,
          pt: 5,
        }}
      >
        Prolotherapy
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What is Prolotherapy?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Prolotherapy also called proliferation therapy or regenerative injection
        therapy is a treatment of tissue with the injection of an irritant
        solution into a joint space, weakened ligament, or tendon insertion to
        relieve pain and to stimulate healing.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How did Prolotherapy originate?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        The concept of creating irritation or injury to stimulate healing has
        been recorded as early as Roman times where hot needles were poked into
        the shoulders of injured gladiators. Prolotherapy originated in the
        1930's when Dr. Earl Gedney, an osteopathic surgeon successfully treated
        his own severe hand injury and was originally used in the treatment of
        ligamentous laxity. In the 1950s Dr. George S. Hackett, a general
        surgeon in the United States, began performing injections of irritant
        solutions in an effort to repair joints and hernias, effectively
        starting the basis for modern Prolotherapy. Dr. Gustav Hemwall, one of
        the earliest students mastered the technique and subsequently brought it
        to the notice of other physicians.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How does Prolotherapy work?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Joints weakened by trauma and overuse, become hypermobile and painful
        when ligaments and tendons attachments are stretched and torn.
        <br />
        With an injection of a mild irritant solution directly on the site of
        the torn or stretched ligament or tendon, Prolotherapy creates a mild
        controlled injury that stimulates the body's natural healing mechanisms
        to lay down new tissue on the weakened area. The mild inflammatory
        response that is created by the injection encourages growth of new
        ligament or tendon fibres, resulting in a tightening of the weakened
        structure. Additional treatments repeat this process, allowing a gradual
        build-up of tissue to restore the original strength to the area.
        <br />
        Thus, Prolotherapy relieves pain by directly impacting the underlying
        condition causing that pain, in contrast to other methods such as
        Painkillers and Steroid injections that may provide temporary pain
        relief.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What are the substances injected?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        The primary component injected in Prolotherapy is Glucose. A local
        anaesthetic is also added to prevent discomfort at time of injection.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Is the treatment painful?{" "}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        As a local anaesthetic is given along with the injection, the injection
        is only mildly discomforting. The mild discomfort passes fairly rapidly
        and can be reduced with pain relievers such as Paracetamol. The
        treatment may result in mild swelling and stiffness.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How often would I need treatment?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        According to the area or joint involved and the intensity of pain,
        treatment can be administered every two weeks (fortnightly) or four
        weeks (monthly). Normally three to five sessions of treatment is
        required.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What areas of the body can be treated?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        This form of therapy can be used to treat different joints; such as
        knee, hip, ankle, wrist, elbow and shoulder. In addition, the cervical,
        thoracic and lumbar spine including the sacroiliac joints can also be
        treated. Prolotherapy is also successful in the treatment of carpal
        tunnel syndrome and temporal mandibular joint dysfunction.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Is Prolotherapy safe?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Prolotherapy is safe as the major component of the injections is
        Glucose. Mild Pain and Irritation at the site quickly subsides and if
        necessary. Very rarely, pain may persist up to 72 hours and can be
        relieved with Paracetamol. Infection is very rare (1 in 300,000
        injections.)
      </Typography>
    </Box>
  );
};

export default ProloTherapy;
