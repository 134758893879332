import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import gallery1 from "../images/gallery-1.jpg";
import gallery2 from "../images/gallery-2.jpg";
import gallery3 from "../images/gallery-3.jpg";
import gallery4 from "../images/gallery-4.jpg";
import gallery5 from "../images/gallery-5.jpg";
import gallery6 from "../images/gallery-6.jpg";
import gallery7 from "../images/gallery-7.jpg";
// import gallery8 from "../images/gallery-8.jpg";

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const Gallery2 = () => {
  return (
    <ImageList
      sx={{ width: "90%", height: "auto" }}
      variant="quilted"
      cols={4}
      rowHeight={121}
    >
      {itemData.map((item) => (
        <ImageListItem
          key={item.img}
          cols={item.cols || 1}
          rows={item.rows || 1}
        >
          <img
            {...srcset(item.img, 121, item.rows, item.cols)}
            // alt={item.title}
            alt="camp photos"
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};
export default Gallery2;
const itemData = [
  {
    img: gallery1,
    title: "gallery1",
    rows: 2,
    cols: 2,
  },
  {
    img: gallery2,
    title: "gallery2",
    rows: 3,
    cols: 2,
  },
  {
    img: gallery3,
    title: "gallery3",
    rows: 2,
  },
  {
    img: gallery4,
    title: "gallery4",
    rows: 2,
  },
  {
    img: gallery5,
    title: "gallery5",
    rows: 3,
    cols: 2,
  },
  {
    img: gallery6,
    title: "gallery6",
    author: "@arwinneil",
    rows: 3,
    cols: 2,
  },
  {
    img: gallery7,
    title: "gallery7",
    cols: 2,
  },
];
