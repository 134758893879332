import React from "react";
import { Box, Typography } from "@mui/material";
import CountUp from "react-countup";

const NumberCard = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "8px",
        px: 1,
        py: 3,

        mb: { xs: 2, md: 0 },
      
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          boxShadow: "6px 6px 12px #b8b9be,-6px -6px 12px #fff",
          alignItems: "center",
          textAlign: "center",
          mb: 4,
        }}
      >
        {props.icon}
      </Box>

      <Typography variant="h4" sx={{ pb: 2 }}>
        <CountUp
          end={props.inView ? props.head : 2}
          suffix={props.suffix}
          duration={2.75}
        />
      </Typography>

      <Typography
        variant="h5"
        sx={{ fontFamily: "Nunito Sans", letterSpacing: "1px" }}
      >
        {props.body}
      </Typography>
    </Box>
  );
};

export default NumberCard;
