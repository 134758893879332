import React, { useState } from "react";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import { NavLink, Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import BiotechIcon from "@mui/icons-material/Biotech";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EngineeringIcon from "@mui/icons-material/Engineering";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";

import { ListItemButton, ListItemIcon } from "@mui/material";

import { sub } from "./Data";
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,

    // color: " rgb(55, 65, 81)",
    border: "1px solid #d1d9e6",

    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        // fontSize: 18,
        // color: theme.palette.text.secondary,
        // marginRight: theme.spacing(1.5),
      },
      "&:hover": {
        // boxShadow:
        //   "inset -5px -5px 7px #ffffffb0,inset  3px 3px 5px rgba(94, 104, 121, 0.692)",
      },
    },
  },
}));
const ListComp = (props) => {
  // const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState();
  // const [open, setOpen] = useState(false);
  // const activeClass = window.location.pathname.startsWith("/acmv")
  //   ? "active"
  //   : "inactive";
  // console.log(activeClass);
  const handleMenuClick = (e) => {
    // setAnchorEl(e.currentTarget);
    if (anchorEl !== e.currentTarget) {
      setAnchorEl(e.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
    // props.onClick();
  };
  const navHandleClick = (e) => {
    e.preventDefault();

    // handleMenuClick();
  };
  // function handlePropClick() {
  //   props.onClick();
  // }
  // onMouseEnter={handleMenuClick}
  //         onMouseLeave={handleMenuClose}
  return (
    <List
      sx={{ display: { xs: "block", md: "flex" }, fontWeight: "bold" }}
      // onClick={props.onClick}
    >
      <NavLink
        to="/"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <HomeIcon sx={{ transform: "translateY(10%)" }} />
            <ListItemText
              primary="Home"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>
      <NavLink
        to="/aboutus"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <BiotechIcon />
            <ListItemText
              primary="About Us"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>
      <NavLink
        onClick={navHandleClick}
        to="/preventive-medicine-therapies"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        // exact="true"
        // onMouseLeave={handleMenuClose}
      >
        <ListItemButton
          onClick={handleMenuClick}
          // className={activeClass}
          // onMouseOver={handleMenuClick}
          // onMouseEnter={handleMenuClick}
          // onMouseLeave={() => setAnchorEl(null)}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <EngineeringIcon />
            <ListItemText
              primary="Services"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
            <ArrowDropDownIcon />
          </ListItemIcon>
        </ListItemButton>
        <StyledMenu
          id="simple-menu"
          keepMounted
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          MenuListProps={{ onMouseLeave: handleMenuClose }}
          // disableRestoreFocus
          onClick={props.onClick}
          PaperProps={{
            style: {
              // maxHeight: 200,
              // width: "20ch",
            },
          }}
        >
          {sub.map((item, i) => (
            <MenuItem
              onClick={() => {
                handleMenuClose();
                // props.onClick;
              }}
              // onClick={() => {
              //   handleMenuClose();
              //   handlePropClick();
              // }}
              component={Link}
              to={item.link}
              key={i}
            >
              {item.title}
            </MenuItem>
          ))}
        </StyledMenu>
      </NavLink>
      <NavLink
        to="/treatment"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          // onMouseLeave={handleMenuClose}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <ConnectWithoutContactIcon />
            <ListItemText
              primary="Treatment"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>
      <NavLink
        to="/testimonials"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          // onMouseLeave={handleMenuClose}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <ConnectWithoutContactIcon />
            <ListItemText
              primary="Testimonials"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>{" "}
      <NavLink
        to="/gallery"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          // onMouseLeave={handleMenuClose}
          sx={{
            borderRadius: "35px",

            "&:hover": {
              //   boxShadow: "inset 2px 2px 5px #b8b9be, inset -3px -3px 7px #fff",
            },
          }}
        >
          <ListItemIcon>
            <ConnectWithoutContactIcon />
            <ListItemText
              primary="Gallery"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>
      <NavLink
        to="/contact"
        className={({ isActive }) => (isActive ? "active" : "inactive")}
        exact="true"
      >
        <ListItemButton
          onClick={props.onClick}
          disableRipple={true}
          // onMouseLeave={handleMenuClose}
          sx={{
            borderRadius: "35px",
          }}
        >
          <ListItemIcon>
            <ConnectWithoutContactIcon />
            <ListItemText
              primary="Contact Us"
              primaryTypographyProps={{
                fontWeight: "inherit",
              }}
            />
          </ListItemIcon>
        </ListItemButton>
      </NavLink>
    </List>
  );
};
export default ListComp;
