import { Box, Typography } from "@mui/material";
import React from "react";
import prolo from "../../images/prolo.png";
import Seo from "../../main/Seo";

const NueralProlotherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a", textAlign: "left", p: 5 }}>
      <Seo
        title="Nueral Prolo therapy -Oxyfresh"
        description="Nueral Prolo therapy "
        name="oxyfresh"
        type="Nueral Prolo therapy  page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${prolo})`,
          height: 400,
          backgroundSize: "cover",
          filter: "blur(4px)",
          // alignSelf: "center",
          // position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          // backgroundColor: "rgb(0,0,0)",65A5BF
          backgroundColor: "rgba(101,165,191, 0.9)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Nueral Prolotherapy
        </Typography>
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#31344b",
          fontWeight: "bold",
          textAlign: "center",
          py: 5,
        }}
      >
        Nueral Prolotherapy
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What is Neural Prolotherapy (NPT)?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Neural Prolotherapy (now also known as Lyftogt Perinueral Injection
        Treatment) was developed by Dr. John Lyftogt of New Zealand. It was
        previously called Subcutaneous Prolotherapy in initial published
        studies. It is a safe and effective treatment for painful conditions due
        to sport and occupation or other chronic non-malignant pain related
        issues.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How does PIT/NPT work?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Irritation or Injury to sensory nerves usually causes pain and
        inflammation in the affected area. Neural Prolotherapy involves a set of
        injections immediately under the skin targeting these painful and
        sensitive nerves with simple and natural substances. These injections
        mainly consist of Glucose or Mannitol in a low concentration.
        <br />
        NPT thus aims to provide immediate relief. The pain will be extinguished
        completely and the treatment area remains pain free for a period of four
        hours to four days. After this the pain will return, but in an intensity
        or form lesser then before. Repeated weekly treatments will steadily
        reduce the overall pain and allow return of full function.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How does PIT/NPT work?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        The average number of treatments required for most conditions is 6-8.
        Success rates vary from 80 to 100% depending on the condition.
        <br />
        Chronic patients may require more injections for a longer duration.
        <br />
        For most patients, Recurrence is unlikely unless re-injury occurs.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Is the treatment safe?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        The treatment is virtually free of side-effects. No allergic reactions
        occur as the substances injected are natural simple sugars. There may be
        local swelling or bruising at the injection sites. Complications such as
        infection are extremely rare (1 in 300,000 injections).
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What conditions can be treated with NPT?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        NPT can be used for the following:-
      </Typography>
      <div style={{ paddingLeft: 50 }}>
        <ur>
          <li>Fibromyalgia</li> <li>Post-herpetic neuralgia</li>
          <li>Headaches, Migraines</li>
          <li>Low Back Pain</li>
          <li>Chronic Pain in extremities</li>
          <li>
            Tendinitis, Arthritis, Bursitis, Sciatica and Muscle/Ligament
            injuries
          </li>
        </ur>
      </div>
    </Box>
  );
};

export default NueralProlotherapy;
