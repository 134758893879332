import { Box, Typography } from "@mui/material";
import React from "react";
import prolo from "../../images/prolo.png";
import Seo from "../../main/Seo";

const BioResonanceTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a", textAlign: "left", p: 5 }}>
      <Seo
        title="Accutouch Therapy -Oxyfresh"
        description="Accutouch Therapy"
        name="oxyfresh"
        type="Accutouch Therapy page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${prolo})`,
          height: 400,
          backgroundSize: "cover",
          filter: "blur(4px)",
          // alignSelf: "center",
          // position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          // backgroundColor: "rgb(0,0,0)",65A5BF
          backgroundColor: "rgba(101,165,191, 0.9)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Bio-Resonance Therapy
        </Typography>
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#31344b",
          fontWeight: "bold",
          textAlign: "center",
          py: 5,
        }}
      >
        Bio-Resonance Therapy{" "}
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What is Bio-resonance therapy?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Bio-resonance therapy is a Diagnostic tool and Treatment modality based
        on electromagnetic fields that are present within the human body.
        Developed on the principles of Dr. Harold S. Burr, measurement and
        manipulation of the electric fields can be used to detect and treat
        various conditions of the body.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What is Bio-resonance? How does it work?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        The Human Body is a complex electromagnetic system that interacts with
        the components within itself creating a Body Energy Field. These, in
        turn, interact with the environment in different frequency bands. They
        can be divided into Basic Frequencies, Supporting Frequencies and
        Energy-informational frequencies of cell exchange.{" "}
      </Typography>
      <Typography variant="body1" sx={{ px: 2 }}>
        Electromagnetic fluctuations control a major aspect of cell to cell
        interactions within the body. They contribute to controlling metabolism,
        stimulating growth, distributing hormones and transmitting pain
        sensations.{" "}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Dr. Harold S. Burr proposed that all living things are formed by fields
        capable of measurement with standard instruments. These electric fields
        reflect physical and mental conditions and are, therefore, of utility in
        diagnosis. He also detected that changes in electromagnetic activity or
        pulses precedes the disease. This means that the diagnosis of
        energy-information exchange of the body is able to assess the state of
        human health, and most importantly - make warning predictions, predict
        disease and take preventive measures to avoid it.{" "}
      </Typography>
      <Typography variant="body1" sx={{ px: 2 }}>
        Physiological fluctuations are electromagnetic oscillations that come
        from healthy organs. Each organ has a spectrum of oscillations which is
        specific only to that particular organ.{" "}
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Radiation frequencies determine the rate of absorption of energy in the
        human body. These variable frequencies help determine the intensity of
        the disease in each particular organ. For example: Normal frequencies
        for the Heart are 700 to 800 Hz, with an increase up to 1500 Hz for
        Angina Pectoris. Normal Liver corresponds to 300 to 400 Hz which
        increases to 600 Hz with increase in inflammation.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What are the advantages over conventional diagnostic scans?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Bio-resonance scan is a Non-Linear Analysis Scan, which allows capturing
        and reflecting in the form of maps and charts the slightest changes in
        biological structures of the entire body.
      </Typography>
      <div style={{ paddingLeft: 50 }}>
        <ul>
          <li>
            The scan is a non-invasive test which has no pre-requisite fasting.
            The test is simple and takes about 35 to 45 minutes to complete.
            There is no exposure to harmful radiation and is an OPD procedure.{" "}
          </li>
          <li>
            As changes in frequencies precede the disease, the scan detects
            disease in the early stages. In some cases, this leads to
            predictions of diseases that may present later.
          </li>
          <li>
            The scanner can be used to check compatibility of the patient and
            his/her medicines. If the aspects of electromagnetic fields are
            similar to some extent, the phenomenon of resonance is possible
            between them.
          </li>
          <li>
            The scanner gives information and details about pathogens if present
            in the body.
          </li>{" "}
          <li>
            The scanner also gives information about Contaminants present and
            Energy Information Burden exerted on the body by various
            environmental factors.
          </li>
        </ul>
      </div>
      <Typography variant="body1" sx={{ p: 2 }}>
        The scan thus helps to give a complete and causal treatment plan which
        can be individualized to any patient, helping achieve good health.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How is Bio-resonance therapy (BRT) and MORA administered?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Bio-resonance therapy normally involves receiving, amplifying, inverting
        and returning back the patient’s own electromagnetic oscillations.
        <br />
        <br />
        Separation and Identification of both Physiological and Pathological
        waves is necessary. These waves can be augmented and inverted as
        required of the disease.
        <br /> <br />
        Through energy-information products or Spectronosodes, the scanner can
        imitate the wave characteristics of medicines and transfer it to a
        soluble matrix ie. Water, Alcohol or Homoeopathic sugar pills. This can
        eliminate the possibility of allergy and compatibility to the patient.
        <br /> <br />
        Bioresonance therapy can reduce the need of medicines and can also
        control dependency to addictive substances.
        <br /> <br />
        The treatment has to be repeated for 5 to 7 cycles for acute illnesses.
        Chronic illnesses require more than 8 cycles.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What scanner do you use?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        We here at the Oxyfresh Medical Centre, use the SENSITIV IMAGO 500CE
        model.
        <br />
        They can be contacted here at sensitivimago.com
      </Typography>
    </Box>
  );
};

export default BioResonanceTherapy;
