import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import contact from "../images/contact.png";
import heartbeat from "../images/heartbeat.png";
import Seo from "../main/Seo";

const Contact = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE" }}>
      <Seo
        title="Contact -Oxyfresh"
        description=" Contact us - we are here for you"
        name="oxyfresh"
        type="Contact page "
      />{" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          color: "#44476a",
        }}
      >
        <Box
          sx={{
            flex: 1,
            mt: {
              xs: "10vh",
              md: "20vh",
            },
            mb: 20,
          }}
        >
          {" "}
          <Typography
            variant="h3"
            sx={{
              // color: "#fff",
              color: "#31344b",
              fontWeight: "bold",
            }}
          >
            Contact Us
          </Typography>
          <img
            src={heartbeat}
            alt="contact"
            style={{
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              paddingTop: 10,
              filter: "blur(.5px)",
            }}
          />
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <img src={contact} alt="contact" style={{ flex: 1, width: "100%" }} />
        </Box>
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#31344b",
          fontWeight: "bold",
          pb: 2,
        }}
      >
        Our Medical Centers
      </Typography>{" "}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3,
          justifyContent: "space-evenly",
          p: 3,
        }}
      >
        <Box
          sx={{ border: "1px solid #2196F3", pb: 2, flex: 1, color: "#44476a" }}
        >
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Mumbai
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            Opposite Naval Park Gate, Behind NCP Office on LBS Marg,
            <br />
            Ghatkopar West, Mumbai - 400086.
            <br />
            <b> Consultation Timings : </b>Fri, Sat, Mon, Tue – 2pm to 4pm; Sun
            – 11am to 3pm
            <br />
            <b>Clinic timings :</b> All days except Wednesday – 11am to 7pm
          </Typography>
          <Button href="tel:+919860385320" variant="outlined" size="small">
            {" "}
            Contact : +91-9860385320
          </Button>
        </Box>
        <Box sx={{ border: "1px solid #2196F3", pb: 2, color: "#44476a" }}>
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Dharavi
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            Kamaraj Building, Above Varsha Gaikwad Office,
            <br />
            Close to Dharavi Police Station, 90 feet road,
            <br />
            Dharavi.
            <br />
            <b>Consultation Timings :</b> Sat & Mon – 6pm to 9pm
          </Typography>
          <Button href="tel:+918452839010" variant="outlined" size="small">
            {" "}
            Contact : +91-8452839010
          </Button>
        </Box>
        <Box
          sx={{ border: "1px solid #2196F3", pb: 2, flex: 1, color: "#44476a" }}
        >
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Chennai
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            115, Ground floor, AH-Block, 7th Main Road,
            <br />
            Shanthi Colony,
            <br />
            Chennai – 600 040.
            <br />
            <b>Consultation Timings :</b> Wed, Thu - 10am to 7pm
            <br />
            <b> Clinic timings :</b> All days except Friday – 10am to 5pm
          </Typography>
          <Button
            href="tel:+04435358707"
            variant="outlined"
            size="small"
            sx={{ mr: 1 }}
          >
            {" "}
            Contact : 044 35358707
          </Button>
          <Button href="tel:+919444258707" variant="outlined" size="small">
            {" "}
            Contact : +91 9444258707
          </Button>
        </Box>
        <Box sx={{ border: "1px solid #2196F3", pb: 2, color: "#44476a" }}>
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Mumbai
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            C-1/94, Vrindavan Society,
            <br />
            LBS Marg, Ghatkopar(West),
            <br />
            Mumbai – 400 086.
            <br />
            <b>Consultation Timings :</b> Fri, Sat, Mon, Tue – 2pm to 4pm; Sun –
            11am to 4pm
            <br />
            <b>Clinic timings :</b> All days except Wednesday – 11am to 7pm
          </Typography>
          <Button
            href="tel:+919860385320"
            variant="outlined"
            size="small"
            sx={{ mr: 1 }}
          >
            {" "}
            Contact : +91 9860385320
          </Button>
          <Button href="tel:+918655331183" variant="outlined" size="small">
            {" "}
            Contact : +91 8655331183
          </Button>
        </Box>
        <Box sx={{ border: "1px solid #2196F3", pb: 2, color: "#44476a" }}>
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Chennai
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            115, Ground floor, AH-Block, 7th Main Road,
            <br />
            Shanthi Colony,
            <br />
            Chennai – 600 040.
            <br />
            <b>Consultation Timings :</b> Wed, Thu -10am to 7pm
            <br />
            <b>Clinic timings :</b> All days except Friday – 10am to 5pm
          </Typography>
          <Button
            href="tel:+04435358707"
            variant="outlined"
            size="small"
            sx={{ mr: 1, mb: 1 }}
          >
            {" "}
            Contact : 044 35358707
          </Button>
          <Button
            href="tel:+919444258707"
            variant="outlined"
            size="small"
            sx={{ mr: 1, mb: 1 }}
          >
            {" "}
            Contact : +91 9444258707
          </Button>
          <Button
            href="tel:+919790930349"
            variant="outlined"
            size="small"
            sx={{ mb: 1 }}
          >
            {" "}
            Contact : +91 9790930349
          </Button>
        </Box>
        <Box sx={{ border: "1px solid #2196F3", pb: 2, color: "#44476a" }}>
          <Typography variant="h6" sx={{ bgcolor: "#2196F3", color: "#fff" }}>
            {" "}
            Oxyfresh Medical Center, Dharavi
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 3 }}>
            Shop No 1/2, Building No 10/A, Transit Camp,
            <br />
            Dharavi.
            <br />
            Mumbai – 400017.
            <br />
            <b>Consultation Timings :</b> Fri, Sat, Mon – 6pm to 11pm
          </Typography>
          <Button href="tel:+02224071984" variant="outlined" size="small">
            {" "}
            Contact : 022 24071984
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          p: 2,
          mx: "auto",

          border: "1px solid #d1d9e6",
          bgcolor: "#FBFFFF",

          width: { xs: "90%", md: "70%" },
          height: 450,
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15545.263624378058!2d80.2080204!3d13.0791544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266a071931667%3A0xdd5d4859ecf8bb4d!2sOxyfresh%20Clinic!5e0!3m2!1sen!2sin!4v1685770614813!5m2!1sen!2sin"
          width="100%"
          height="100%"
          style={{
            border: 0,
          }}
          loading="lazy"
          title="map location"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Box>
  );
};

export default Contact;
