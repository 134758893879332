import React, { Suspense } from "react";
// import "./App.css";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ErrorPage from "./main/ErrorPage";
// import Loader from "./main/Loader";

import CircularProgress from "@mui/material/CircularProgress";
import Root from "./pages/Root";
import Hero from "./components/hero/Hero";
import AboutUs from "./pages/AboutUs";
import Treatment from "./pages/Treatment";
import Gallery3 from "./pages/Gallery3";
import Contact from "./pages/Contact";
import Testimonials from "./pages/Testimonials";
import PreventiveMedicineTherapies from "./components/servicepages/PreventiveMedicineTherapies";
import OzoneTherapy from "./components/servicepages/OzoneTherapy";

import ProlozoneTherapy from "./components/servicepages/ProlozoneTherapy";
import ProloTherapy from "./components/servicepages/ProloTherapy";
import BodyAlkalinizingTreatmentsForCancer from "./components/servicepages/BodyAlkalinizingTreatmentsForCancer";
import AccutouchTherapy from "./components/servicepages/AccutouchTherapy";
import Homeopathy from "./components/servicepages/Homeopathy";
import SiddhaMedicine from "./components/servicepages/SiddhaMedicine";
import HydrogenPeroxideTherapy from "./components/servicepages/HydrogenPeroxideTherapy";
import EdtaChelationTherapy from "./components/servicepages/EdtaChelationTherapy";
import BioResonanceTherapy from "./components/servicepages/BioResonanceTherapy";
import NueralProlotherapy from "./components/servicepages/NueralProlotherapy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Hero />,
      },
      {
        path: "/aboutus",
        element: <AboutUs />,
      },

      {
        path: "treatment",
        element: <Treatment />,
      },
      {
        path: "gallery",
        element: <Gallery3 />,
      },
      {
        path: "testimonials",
        element: <Testimonials />,
      },

      {
        path: "/preventive-medicine-therapies",
        element: <PreventiveMedicineTherapies />,
      },
      {
        path: "/ozone-therapy",
        element: <OzoneTherapy />,
      },
      {
        path: "/prolozone-therapy",
        element: <ProlozoneTherapy />,
      },
      {
        path: "/prolo-therapy",
        element: <ProloTherapy />,
      },
      {
        path: "/nueral-prolotherapy",
        element: <NueralProlotherapy />,
      },
      {
        path: "/bio-resonance-therapy",
        element: <BioResonanceTherapy />,
      },
      {
        path: "/edta-chelation-therapy",
        element: <EdtaChelationTherapy />,
      },
      {
        path: "/hydrogen-peroxide-therapy",
        element: <HydrogenPeroxideTherapy />,
      },
      {
        path: "/siddha-medicine",
        element: <SiddhaMedicine />,
      },
      {
        path: "/homeopathy",
        element: <Homeopathy />,
      },
      {
        path: "/accutouch-therapy",
        element: <AccutouchTherapy />,
      },
      {
        path: "/body-alkalinizing-treatments-for-cancer",
        element: <BodyAlkalinizingTreatmentsForCancer />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      // {
      //   path: "/privacy-policy",
      //   element: <PrivacyPolicy />,
      // },
      // {
      //   path: "/terms&conditions",
      //   element: <TermsAndConditions />,
      // },
    ],
  },
]);

const App = () => {
  return (
    <div>
      <Suspense fallback={<CircularProgress variant="determinate" />}>
        <RouterProvider router={router} />
      </Suspense>
    </div>
  );
};

export default App;
