import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import treatment1 from "../images/treatment.png";
import Seo from "../main/Seo";
const Treatment = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a" }}>
      <Seo
        title="Treatments -Oxyfresh"
        description="Different types of treatments and its methods"
        name="oxyfresh"
        type="Treatments page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage:
            "url('https://img.freepik.com/free-vector/doctor-examining-patient-clinic-illustrated_23-2148856560.jpg?w=740&t=st=1686035044~exp=1686035644~hmac=3e6eae79d5956ea6c66d180ab228ed4d27b023e3fa0e30daf3b03dbf35d3eac2')",
          height: 500,
          backgroundSize: "cover",
          filter: "blur(5px)",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "rgba(101,165,191,0.7)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          Treatments
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", p: { xs: 1, md: 5 } }}
      >
        {" "}
        <Typography
          variant="h4"
          sx={{
            color: "#31344b",
            textDecoration: "underline",
            textDecorationColor: "#31344b",
          }}
        >
          Treatment 1
        </Typography>{" "}
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box sx={{ flex: 2, p: 5, textAlign: "left" }}>
            <Typography variant="body1">
              Born in Mumbai and having graduated from Grant Medical College and
              JJ Hospital, Byculla, Mumbai. Dr. Kannan RajamaniKarnam, MBBS
              began practicing allopathy in Jan 1980. Within two years, the
              limitations of this form of medicine struck him.
            </Typography>
            <div
              style={{
                borderLeft: "3px solid #CCCCCC",
                height: "40px",
              }}
            ></div>
            <Typography variant="body1">
              Though dubbed the best form of treatment, it had no cures for any
              chronic illnesses and was proficient in converting any acute
              illness to a chronic one. His search for alternates led him to
              learn homeopathy from the association of homeopathy in Mumbai and
              Siddha medicine from reknowned Dr. Srilasri GANAPATHY SWAMIGAL,
              Madurai.
            </Typography>
          </Box>
          <img
            style={{ flex: 1, borderRadius: "9px", width: 325 }}
            alt="treatment 1"
            src={treatment1}
          />
        </Box>
        <Divider sx={{ p: 5 }} />
        <Box
          sx={{ display: "flex", flexDirection: "column", p: { xs: 1, md: 5 } }}
        >
          {" "}
          <Typography
            variant="h4"
            sx={{
              color: "#31344b",
              textDecoration: "underline",
              textDecorationColor: "#31344b",
            }}
          >
            Treatment 2
          </Typography>{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 5,
            }}
          >
            <Box sx={{ flex: 2, textAlign: "left", pt: 3 }}>
              <Typography variant="body1">
                Though dubbed the best form of treatment, it had no cures for
                any chronic illnesses and was proficient in converting any acute
                illness to a chronic one. His search for alternates led him to
                learn homeopathy from the association of homeopathy in Mumbai
                and Siddha medicine from reknowned Dr. Srilasri GANAPATHY
                SWAMIGAL, Madurai.
              </Typography>
              <div
                style={{
                  borderLeft: "3px solid #CCCCCC",
                  height: "40px",
                }}
              ></div>
              <Typography variant="body1">
                Born in Mumbai and having graduated from Grant Medical College
                and JJ Hospital, Byculla, Mumbai. Dr. Kannan RajamaniKarnam,
                MBBS began practicing allopathy in Jan 1980. Within two years,
                the limitations of this form of medicine struck him.
              </Typography>
            </Box>
            <img
              style={{ flex: 1, borderRadius: "9px", width: 325 }}
              alt="treatment 1"
              src={treatment1}
            />
          </Box>
        </Box>{" "}
      </Box>
    </Box>
  );
};

export default Treatment;
