import React from "react";
import IconButton from "@mui/material/IconButton";

const SocialButton = (props) => {
  return (
    <IconButton
      aria-label={props.name}
      href={props.link}
      target="_blank"
      color="success"
      sx={{
        mx: "auto",
        "&:hover": { color: "#B7F3B5", backgroundColor: "#2E7D32" },
      }}
    >
      {props.icon}
    </IconButton>
  );
};

export default SocialButton;
