import React from "react";
import UnderConstruction from "../../utils/UnderConstruction";
import { Box } from "@mui/material";
import Seo from "../../main/Seo";

const AccutouchTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE" }}>
      <Seo
        title="Accutouch Therapy -Oxyfresh"
        description="Accutouch Therapy"
        name="oxyfresh"
        type="Accutouch Therapy page "
      />{" "}
      <UnderConstruction />
      AccutouchTherapy
    </Box>
  );
};

export default AccutouchTherapy;
