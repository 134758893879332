import React from "react";
import UnderConstruction from "../../utils/UnderConstruction";
import { Box } from "@mui/material";
import Seo from "../../main/Seo";

const SiddhaMedicine = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE" }}>
      <Seo
        title="Siddha Medicine -Oxyfresh"
        description="Siddha Medicine therapy"
        name="oxyfresh"
        type="Siddha Medicine page "
      />{" "}
      <UnderConstruction />
      SiddhaMedicine
    </Box>
  );
};

export default SiddhaMedicine;
