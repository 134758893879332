import { Box, Typography } from "@mui/material";
import React from "react";
import prolo from "../../images/prolo.png";
import Seo from "../../main/Seo";

const EdtaChelationTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a", textAlign: "left", p: 5 }}>
      <Seo
        title="Edta Chelation Therapy -Oxyfresh"
        description="Edta Chelation Therapy"
        name="oxyfresh"
        type="Edta Chelation Therapy page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${prolo})`,
          height: 400,
          backgroundSize: "cover",
          filter: "blur(4px)",
          // alignSelf: "center",
          // position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          // backgroundColor: "rgb(0,0,0)",65A5BF
          backgroundColor: "rgba(101,165,191, 0.9)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          EDTA Chelation Therapy{" "}
        </Typography>
      </Box>
      <Typography
        variant="h3"
        sx={{
          color: "#31344b",
          fontWeight: "bold",
          textAlign: "center",
          py: 5,
        }}
      >
        EDTA Chelation Therapy{" "}
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        What is Chelation therapy?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Chelation therapy (Pronounced key-lay-shun) is a medical procedure used
        in conventional medicine that involves the administration of chelating
        agents to remove heavy metals from the body. It involves intravenous
        injections of a chelating agent, EDTA (ethylene diamine tetra-acetic
        acid), a synthetic amino acid. EDTA binds to heavy metals and minerals
        in the blood so that they can be excreted in the urine.
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        However because EDTA can reduce the amount of calcium in the
        bloodstream, and because calcium is found within the plaque that can
        line diseased blood vessels, chelation therapy can be used to treat
        atherosclerosis (hardening of the arteries) by reopening arteries
        clogged with plaque.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How does Chelation Therapy work?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Heavy metal catalysts induce chemical reactions and cause damage to the
        cell membranes by producing free radicals. Free radical damage is an
        important cause of Aging, Atherosclerosis and Cancer. By aiding the
        removal of heavy metals, EDTA helps reduce production of free radicals,
        hence preventing major diseases.
        <br />
        <br />
        Atherosclerotic plaque, after many years has the tendency to become
        calcified. Calcium tends to deposit close to the atheroma and also on
        the atheroma plaques and surface. EDTA can reduce the amount of calcium
        in the bloodstream thereby reopening arteries affected by such plaque as
        well as reducing further incidence of formation and worsening.
        <br /> <br />
        Through energy-information products or Spectronosodes, the scanner can
        imitate the wave characteristics of medicines and transfer it to a
        soluble matrix ie. Water, Alcohol or Homoeopathic sugar pills. This can
        eliminate the possibility of allergy and compatibility to the patient.
        <br /> <br />
        The normal aging process causes calcium deposition in the arterial wall
        making the artery harder and less pliable. EDTA reverses this process
        and improves Blood pressure and blood supply to the extremities.
        <br /> <br />
        EDTA improves calcium metabolism by stimulating secretion of Parathyroid
        hormone. EDTA can thus improve bone density over time.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Who will benefit from Chelation Therapy?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Anyone with circulatory problems can undergo Chelation therapy. Patients
        who have had a history of stroke or heart disease will benefit from the
        treatment. Patients with family history of circulatory diseases can also
        be considered for treatment.
      </Typography>
      <div style={{ paddingLeft: 50 }}>
        <ul>
          <li>Angina</li>
          <li>Heavy metal toxicity</li>
          <li>Arterial diseases</li>
          <li>Arthritis</li> <li>Peripheral Artery Disease</li>
          <li>High blood pressure</li>
          <li>Parkinson’s disease and Alzheimer’s disease</li>
          <li>Psoriasis</li>
          <li>Scleroderma</li>
          <li>Rheumatoid Arthritis</li>
          <li>Autoimmune disease</li>
          <li>Chronic pain</li>
          <li>Aging or Memory loss</li>
          <li>Diabetes</li>
        </ul>
      </div>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Can Chelation benefit Healthy Individuals?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Chelation can, and is used as a prophylactic and preventive measure
        against Atherosclerosis in non-symptomatic people, especially high risk
        individuals. This can prevent many complications in the future.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        Are there any side effects of Chelation therapy?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        EDTA chelation therapy is relatively non-toxic and risk-free, especially
        when compared with other treatments. The risk of significant side
        effects, when properly administered, is less than 1 in 10,000 patients
        treated. More than 400,000 patients have received over four million
        treatments during the past 30 years. It is estimated by the American
        College for Advancement in Medicine, a professional association that
        supports the use of chelation therapy, that more than 800,000 visits for
        chelation therapy were made in the United States in 1997 alone.
        <br />
        <br />A burning sensation at the site of the injection is the most
        common side effect following treatment. Some temporarily experience mild
        nausea, dizziness, or headache as an immediate aftermath of treatment,
        but in the vast majority of cases, these minor symptoms are easily
        relieved.
      </Typography>
      <Typography variant="h5" sx={{ color: "#31344b" }}>
        How many sittings of Chelation therapy will be required?
      </Typography>
      <Typography variant="body1" sx={{ p: 2 }}>
        Chelation therapy usually consists of anywhere from 20 to 40 separate
        infusions, depending on each patient’s individual health status.
        Patients with symptoms of Arterial blockage will require a minimum of 30
        sittings for optimum benefit. As a prophylactic measure, Healthy
        patients would require around 20 sittings.
        <br />
        <br />
        Each Chelation treatment takes three hours or longer and patients
        receive treatment weekly or twice weekly as per their convenience and
        schedule.
        <br />
        <br />
        Chelation is an out-patient procedure and the patient does not need
        admission for treatment.
      </Typography>
    </Box>
  );
};

export default EdtaChelationTherapy;
