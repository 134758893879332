import React from "react";
import { Box, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
const TeamCard = (props) => {
  return (
    <Box
      sx={{
        boxShadow: " 6px 6px 12px #b8b9be,-6px -6px 12px #E6E7EE",
        mt: 10,
       
        borderRadius: "9px",
        backgroundColor: "#EDF0F6",
        width: { xs: "auto", md: 350 },
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          p: 2,
          borderRadius: "50%",
          boxShadow: "inset 2px 2px 5px #b8b9be,inset -3px -3px 7px #fff",

          width: "160px",
          height: "160px",
          border: "1px solid #d1d9e6",
          mt: "-80px",
          mx: "auto",
          backgroundColor: "#EDF0F6",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={props.img}
          alt={props.head}
          width="160px"
          height="160px"
          style={{ color: "#94D82D", borderRadius: "50%" }}
        />
        
      </Box>
      <Box sx={{ px: 4, pt: 2, pb: 3, textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{ pb: 2, color: "#31344B", fontWeight: 600 }}
        >
          {props.head}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontFamily: "Nunito Sans", color: "#31344B" }}
        >
          {/* {props.body} */}
          physiotherapist
        </Typography>
        <Box
          sx={{
            display: "flex",
            mx: "auto",
            justifyContent: "space-evenly",
            mt: 3,
          }}
        >
          <FacebookIcon style={{ color: "#31344B" }} />
          <InstagramIcon style={{ color: "#31344B" }} />
          <TwitterIcon style={{ color: "#31344B" }} />
        </Box>
        <Typography
          variant="body1"
          sx={{ fontFamily: "Nunito Sans", color: "#31344B", mt: 1 }}
        >
          Specialize in surgery and am committed to delivering compassionate and
          comprehensive care.
        </Typography>
      </Box>
    </Box>
  );
};

export default TeamCard;
