import React from "react";
import { Box, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const Hero4 = () => {
  const awards = [
    { head: "ROTARY VOCATIONAL EXCELLENCE AWARD" },
    { head: "ROTARY INTERNATIONAL PAUL HARRIS AWARD" },
    { head: "ROTARY DOCTORS TROPHY" },
    { head: "DISTINGUISHED SERVICE AWARDS" },
    { head: "SEVA BHUSHAN" },
    { head: "SAMAJ SHAKTI AWARD" },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        p: { xs: 2, md: 5 },
        gap: 5,
        backgroundColor: "#E6E7EE",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography
          variant="h5"
          sx={{ pb: 3, fontWeight: "bold", color: "#31344b" }}
        >
          Videos
        </Typography>
        <Box
          sx={{
            p: 2,
            boxShadow: "6px 6px 12px #b8b9be,-6px -6px 12px #E6E7EE",
            borderRadius: "9px",
            border: "1px solid #d1d9e6",
          }}
        >
          <iframe
            width="95%"
            height="309"
            src="https://www.youtube.com/embed/knf2hYt1YIs"
            title="Ozone therapy feedback Hepatitis C, Dr.Karnam, Oxyfresh Medical Center, Mumbai, India"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        {" "}
        <Typography
          variant="h5"
          sx={{ pb: 3, fontWeight: "bold", color: "#31344b" }}
        >
          Awards
        </Typography>
        <Box
          sx={{
            p: 2,
            boxShadow: "6px 6px 12px #b8b9be,-6px -6px 12px #E6E7EE",
            borderRadius: "9px",
            border: "1px solid #d1d9e6",
          }}
        >
          <List
            sx={{
              color: "#31344B",
              bgcolor: "#F6F9FF",
              borderRadius: "9px",
            }}
            aria-label="contacts"
          >
            {awards.map((item, i) => (
              <ListItem disablePadding key={i}>
                <ListItemButton>
                  <ListItemIcon>
                    <StarIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={item.head}
                    primaryTypographyProps={{
                      fontWeight: "bold",
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default Hero4;
