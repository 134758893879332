export const sub = [
  {
    title: "Preventive Medicine Therapies",
    link: "/preventive-medicine-therapies",
  },
  {
    title: "Ozone Therapy",
    link: "/ozone-therapy",
  },
  {
    title: "Prolozone Therapy",
    link: "/prolozone-therapy",
  },
  {
    title: "Prolo Therapy",
    link: "/prolo-therapy",
  },
  {
    title: "Nueral Prolotherapy",
    link: "/nueral-prolotherapy",
  },
  {
    title: "Bio-resonance Therapy",
    link: "/bio-resonance-therapy",
  },
  {
    title: "EDTA Chelation Therapy",
    link: "/edta-chelation-therapy",
  },
  {
    title: "Hydrogen Peroxide Therapy",
    link: "/hydrogen-peroxide-therapy",
  },
  {
    title: "Siddha Medicine",
    link: "/siddha-medicine",
  },
  {
    title: "Homeopathy",
    link: "/homeopathy",
  },
  {
    title: "Accutouch Therapy",
    link: "/accutouch-therapy",
  },
  {
    title: "Body Alkalinizing Treatments For Cancer",
    link: "/body-alkalinizing-treatments-for-cancer",
  },
];
