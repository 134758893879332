import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import List from "@mui/material/List";
// import ListItem from "@mui/material/ListItem";
import { CssBaseline, Divider } from "@mui/material";
import ozone from "../../images/ozone.jpg";
import ozone1 from "../../images/ozone1.jpg";
import ozone2 from "../../images/ozone2.jpg";

import ozone3 from "../../images/ozone3.jpg";
import Seo from "../../main/Seo";

const OzoneTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a" }}>
      <Seo
        title="Ozone Therapy -Oxyfresh"
        description="Ozone Therapy"
        name="oxyfresh"
        type="Ozone Therapy page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${ozone})`,
          height: 400,
          backgroundSize: "cover",
          filter: "blur(4px)",
          // alignSelf: "center",
          // position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          // backgroundColor: "rgb(0,0,0)",65A5BF
          backgroundColor: "rgba(101,165,191,0.7)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
            // whiteSpace: "nowrap",
          }}
        >
          Ozone Therapy
        </Typography>
      </Box>
      <Box
        sx={{ display: "flex", flexDirection: "column", p: { xs: 1, md: 5 } }}
      >
        {" "}
        <Typography
          variant="h4"
          sx={{
            color: "#31344b",
            textDecoration: "underline",
            textDecorationColor: "#31344b",
            p: 3,
          }}
        >
          Ozone Therapy
        </Typography>{" "}
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
        >
          <Box sx={{ flex: 2, p: 5, textAlign: "left" }}>
            <Typography variant="body1" sx={{ pb: 2 }}>
              Ozone is an unstable but highly beneficial molecule, it is
              composed of three atoms of oxygen. Ozone breaks down into oxygen
              (two atoms of oxygen) and a single atom of oxygen as shown below :
              O3 -----------&gt; O2 + [O] Nascent Oxygen Molecule
            </Typography>
            <Typography variant="body1">
              The singlet oxygen atom is highly reactive and reacts with any
              substance that should NOT be in the human body including all
              pathogens (virus, bacteria, etc.) and synthetic compounds or their
              metabolites such as drugs and their metabolite residues. Ozone is
              an incredible rejuvenator, normalizer, detoxifier, regenerator,
              immune enhancer and physical and mental energizer. Ozone therapy
              involves the therapeutic and regulated use of ozone for the
              treatment of various ailments and illnesses. Ozone can be applied
              to the body in a number of ways ranging from intravenous injection
              to rectal insufflation. It is safe, simple and painless. It does
              not need admission, is an OPD procedure and needs no anaesthesia.
              The sessions are once or twice a week totaling 15-20 sittings.
              Please read the FAQ for more detailed information.
            </Typography>
            <Typography variant="body1" sx={{ pb: 2 }}>
              Oxygen is an important factor to sustain life. Lack of adequate
              oxygen is known to promote cancer and many other medical diseases.
              Ozone Gas is a very powerful Oxygen molecule containing 3 atoms of
              oxygen. Ozone machines generate Ozone Gas by giving high-voltage
              electric sparks to medical grade oxygen gas passing through the
              machine.
            </Typography>
            <Typography variant="body1" sx={{ pb: 2 }}>
              OZONE THERAPY was discovered in Germany well over one hundred
              years ago but did not come in common use till recently. It is now
              becoming more popular in various parts of the world.
            </Typography>
            <Typography variant="body1" sx={{ pb: 2 }}>
              Ozone Therapy Clinics are now being established in various parts
              of India to compliment treatment in other existing medical clinics
              and hospitals. Ozone therapy is safe, simple and painless. Ozone
              cannot be given directly through nose like oxygen. Exactly
              measured quantities of Ozone gas are administered through rectum
              as an enema, vagina, eardrums, mixed in blood or saline infusions.
              Ozone gas and ozonized oils are also applied over the affected
              parts and ulcers for quick healing. Each treatment takes only a
              few minutes. No anesthesia is required and patients can talk
              cheerfully during the treatment. These sessions are given two or
              three times weekly for total of about 15 to 20 sittings. It may be
              continued thereafter if needed.
            </Typography>
            <Typography variant="body1" sx={{ pb: 2 }}>
              OZONE THERAPY is an incredible rejuvenator, normalizer,
              detoxifier, regenerator, immune enhancer and physical and mental
              energizer.
            </Typography>
          </Box>
          <Box
            sx={{
              flex: 1,
              width: 300,
              // flexDirection: "center",
              // display: "flex",
              mx: "auto",
            }}
          >
            <img
              style={{ width: 300, borderRadius: "9px" }}
              alt="ozone theraphy"
              src={ozone1}
            />
            <img
              style={{ width: "inherit", borderRadius: "9px" }}
              alt="ozone theraphy"
              src={ozone2}
            />
            <img
              style={{ width: "inherit", borderRadius: "9px" }}
              alt="ozone theraphy"
              src={ozone3}
            />
          </Box>
        </Box>
        <CssBaseline />
        <Divider />
        <Box sx={{ textAlign: "left" }}>
          <Typography
            variant="h5"
            sx={{ py: 2, textAlign: "center", color: "#31344b" }}
          >
            Benefits Of Ozone Therapy
          </Typography>
          <div style={{ paddingLeft: 50, paddingBottom: 30 }}>
            <ol>
              <li>Speeds up the metabolic processes.</li>
              <li>
                Stimulates immune system by increasing the White Blood Cell
                count.
              </li>
              <li>
                Increases circulation, oxygen and nutrient delivery within the
                body. Increases the amount of oxygen in the blood. Improves
                brain function and memory.
              </li>
              <li>
                Oxidizes toxins that have accumulated in the body such as
                pesticides, drug residues, acidic wastes and more. Toxins are
                then eliminated through the skin, lungs, kidneys and the colon.
              </li>{" "}
              <li>
                Purifies the blood and the lymph. Cleans arteries and veins.
                Normalizes hormone and enzyme production.
              </li>
              <li>Stimulates weight loss and reduces cellulite.</li>
              <li>
                Supports and enhances the healing process. Deactivates viruses,
                bacteria, eczema, fungi, etc. Ozone is effective in treating
                most allergies.
              </li>
              <li>
                Promotes relaxation and loosens muscles by reducing the build-up
                of lactic acid. Helps injured muscles to repair more quickly.
                Increases flexibility, reduces inflammation and relieves pain.
              </li>
            </ol>
          </div>
          <Divider />

          <Typography
            variant="h6"
            sx={{ py: 2, textAlign: "center", color: "#31344b" }}
          >
            OZONE THERAPY can be considered as a supportive therapy to help in
            following condition
          </Typography>
          <div style={{ paddingLeft: 50 }}>
            <ol>
              <li>
                Chronic Infections due to viruses, bacteria, fungi and other
                germs.
              </li>
              <li>
                Non-healing wounds and ulcers, diabetic ulcers, surgical wound
                infections.
              </li>
              <li>Circulatory disorders.</li>
              <li>
                Skin conditions like eczema, infections, bedsores, ulcers.
              </li>{" "}
              <li>Gynecological infection.</li>
              <li>
                Ear Nose Throat ENT Infections, Sinus infections, Bronchitis
                etc.
              </li>
              <li>Arthritis, Rheumatism, Backaches, Spondylitis.</li>
              <li>
                Help in cancer as supportive treatment to other mainline
                treatments.
              </li>
              <li>Liver diseases. cirrhosis, hepatitis.</li>
              <li>Brain disorders, Parkinson's, Memory Impairments etc.</li>
            </ol>
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default OzoneTherapy;
