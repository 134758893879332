import React from "react";
import SimpleImageSlider from "react-simple-image-slider";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";
import Hero4 from "./Hero4";
import Gallery from "../Gallery";
import Seo from "../../main/Seo";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import banner1 from "../../images/banner1.jpeg";
import banner2 from "../../images/banner2.jpeg";
import banner3 from "../../images/banner3.jpeg";
import banner4 from "../../images/banner4.jpeg";

const Hero = () => {
  const sliderImages = [
    {
      url: banner2,
    },
    {
      url: banner1,
    },

    {
      url: banner3,
    },
    {
      url: banner4,
    },
  ];
  return (
    <div>
      <Seo
        title="Oxyfresh"
        description=" Welcome to oxyfresh Medical Centre"
        name="oxyfresh"
        type="landing page "
      />
      <SimpleImageSlider
        width={"100%"}
        height={550}
        images={sliderImages}
        showBullets={true}
        showNavs={true}
        autoPlay={true}
        // onStartSlide={(index, length) => {
        //   setImageNum(index);
        // }}
        autoPlayDelay={3}
      />
      <Hero2 />
      <Hero4 />
      <Box sx={{ p: { xs: 1, md: 5 }, bgcolor: "#E6E7EE", mx: "auto" }}>
        <Card
          sx={{
            minWidth: 225,
            bgcolor: "#033655",
            borderTopRightRadius: "50%",
            borderTopLeftRadius: "15px",
            borderBottomRightRadius: "15px",

            borderBottomLeftRadius: "50%",

            p: { xs: 4, md: 5 },
          }}
        >
          <CardContent>
            <Box sx={{ display: "flex" }}>
              {" "}
              <FormatQuoteIcon
                sx={{ color: "#fff", transform: "translateY(-25%)" }}
                size="large"
              />
              <Typography variant="h5" sx={{ color: "#FFECCC" }}>
                One of the first duties of the physician is to educate the
                masses not to take medicine.{" "}
              </Typography>{" "}
              <FormatQuoteIcon
                sx={{ color: "#fff", transform: "translateY(-25%)" }}
                size="large"
              />
            </Box>{" "}
          </CardContent>
          <CardActions>
            <Button
              size="small"
              sx={{
                ml: "auto",
                color: "#00D2E4",
                "&:hover": { bgcolor: "#033655" },
              }}
            >
              -Sir WILLIAM OSLER, Physician.{" "}
            </Button>
          </CardActions>
        </Card>
      </Box>
      <Hero3 />
      <Gallery />
      {/* <Gallery2 /> */}
    </div>
  );
};

export default Hero;
