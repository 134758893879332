import React from "react";
import { Box, Typography, Button } from "@mui/material";
import SocialButton from "../utils/SocioButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const Footer = () => {
  const socio = [
    {
      name: "Facebook",
      icon: <FacebookIcon fontSize="medium" />,
      link: "https://www.facebook.com",
    },
    { name: "Twitter", icon: <TwitterIcon />, link: "http://www.twitter.com" },
    {
      name: "Instagram",
      icon: <InstagramIcon fontSize="medium" />,
      link: "https://instagram.com",
    },
    {
      name: "Linkedin",
      icon: <YouTubeIcon fontSize="medium" />,
      link: "https://www.youtube.com",
    },
    {
      name: "Email",
      icon: <EmailIcon fontSize="medium" />,
      link: "mailto:oxy4sh@gmail.com",
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#E6E7EE",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ backgroundColor: "#B7F3B5" }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#E6E7EE"
              fillOpacity="1"
              d="M0,288L48,288C96,288,192,288,288,277.3C384,267,480,245,576,245.3C672,245,768,267,864,266.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z"
            ></path>
          </svg>
        </Box>
        <Box
          sx={{
            backgroundColor: "#B7F3B5",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            pb: 2,
            gap: 4,
          }}
        >
          <Box
            sx={{
              flex: 1,
              
              mt: 5,
            }}
          >
            <img
              src="https://web.archive.org/web/20181205171602im_/http://oxyfreshclinic.com/images/logo.png"
              alt="logo"
              style={{
              
                filter: " hue-rotate(180deg)",
               
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Typography variant="body1" sx={{ color: "#2E7D32" }}>
                Privacy Policy
              </Typography>
              <Divider orientation="vertical" flexItem />
              <Typography variant="body1" sx={{ color: "#2E7D32" }}>
                Terms & Conditions
              </Typography>
            </Box>
          </Box>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ bgcolor: "#2E7D32" }}
          />

          <Box sx={{ flex: 1, textAlign: "center" }}>
            <Typography
              variant="h5"
              sx={{
                color: "#2E7D32",
                fontWeight: "bold",
                mb: 3,
                textDecoration: "underline",
              }}
            >
              {" "}
              Reach Us
            </Typography>
            <Button
              startIcon={<LocationOnIcon />}
              href="https://goo.gl/maps/hjjTF7ovP8Vxfs2e9"
              target="_blank"
              sx={{ color: "#2E7D32", "&:hover": { bgcolor: "#B7F3B5" } }}
            >
              {" "}
              AH-115,7th Main Road,
              <br />
              Shanthi colony, Anna Nagar,
              <br />
              Chennai 600 040.
            </Button>

            <Button
              variant="outlined"
              href="mailto:drkrkarnam@gmail.com"
              target="_blank"
              startIcon={<EmailIcon />}
              sx={{
                mt: 2,
                borderColor: "#2E7D32",
                color: "#2E7D32",
                borderRadius: "20px",
                textTransform: "lowercase",
                "&:hover": {
                  color: "#fff",
                  backgroundColor: "#2E7D32",
                  borderColor: "#2E7D32",
                },
              }}
            >
              drkrkarnam@gmail.com
            </Button>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                // variant="contained"
                href="tel:+919860385320"
                startIcon={<PhoneInTalkIcon />}
                sx={{
                  // borderColor: "#2E7D32",
                  borderColor: "#2E7D32",
                  color: "#2E7D32",
                  borderRadius: "20px",
                  boxSizing: "border-box",
                  mr: 2,
                  mt: 1,
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#2E7D32",
                    borderColor: "#2E7D32",
                  },
                }}
              >
                +91 98603 85320
              </Button>
              <Button
                variant="outlined"
                href="tel:+919444258707"
                startIcon={<PhoneInTalkIcon />}
                // sx={{ mr: 1 }}
                sx={{
                  borderColor: "#2E7D32",
                  color: "#2E7D32",
                  borderRadius: "20px",

                  mt: 1,
                  "&:hover": {
                    color: "#fff",
                    backgroundColor: "#2E7D32",
                    borderColor: "#2E7D32",
                  },
                }}
              >
                +91 9444258707
              </Button>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box sx={{ flex: 1 }}>
           
            <Typography
              variant="h5"
              sx={{
                color: "#2E7D32",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
            >
              {" "}
              Follow Us
            </Typography>

            <Stack
              sx={{
                mt: 3,
              }}
            >
              {socio.map((item, i) => (
                <SocialButton
                  name={item.name}
                  icon={item.icon}
                  key={i}
                  link={item.link}
                />
              ))}
            </Stack>
          </Box>
        </Box>
      </Box>
      <Box sx={{ backgroundColor: "#B7F3B5" }}>
        <Divider color="#2E7D32" sx={{ mx: 5 }} />
        <Button sx={{ color: "#2E7D32" }} size="small">
          {"Copyright  © "}
          {new Date().getFullYear()}
          {" - OXYFRESH"}
          {"."}
          {"(All Rights Reserved)"}
          {/* <br /> */}
        </Button>
      </Box>
    </Box>
  );
};

export default Footer;
