import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import { Drawer, Box } from "@mui/material";
import ListComp from "./ListComp";
import { useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
function NavBar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const navigate = useNavigate();
  const to = () => {
    navigate("/");
  };
  return (
    <AppBar
      // position="static"
      elevation={0}
      position="sticky"
      sx={{
        backgroundColor: "#E6E7EE",
      }}
    >
      <Toolbar>
        <img src={logo} alt="logo" onClick={to} />

        <Box sx={{ display: "flex", ml: "auto", alignItems: "center" }}>
          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              ml: "auto",
              whiteSpace: "nowrap",
              my: 1,
            }}
          >
            <ListComp />
          </Box>
          <IconButton
            onClick={() => setOpenDrawer((prev) => !prev)}
            color="#2196F3"
            sx={{
              display: { xs: "flex", md: "none" },
              ml: "auto",
            }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        <Drawer
          open={openDrawer}
          // variant="temporary"
          anchor={"right"}
          onClose={() => {
            setOpenDrawer(false);
          }}
        >
          <ListComp
            sx={{ bgcolor: "background.paper" }}
            onClick={() => setOpenDrawer(false)}
          />
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
export default NavBar;
