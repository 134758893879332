import React from "react";
import { Box } from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import ScienceIcon from "@mui/icons-material/Science";
import NumberCard from "../../utils/NumberCard";
import { InView } from "react-intersection-observer";

const Hero3 = () => {
  const aboutCardContent = [
    {
      head: "365",
      body: "Days",
      icon: <CalendarMonthIcon fontSize="large" style={{ color: "#31344B" }} />,
      suffix: "",
    },
    {
      head: "125",
      body: "Doctors",
      icon: <LocalHospitalIcon fontSize="large" style={{ color: "#31344B" }} />,
      suffix: "",
    },
    {
      head: "25",
      body: "Patients",
      icon: <VaccinesIcon fontSize="large" style={{ color: "#31344B" }} />,
      suffix: "k",
    },
    {
      head: "12",
      body: "Lab results",
      icon: <ScienceIcon fontSize="large" style={{ color: "#31344B" }} />,
      suffix: "k",
    },
  ];
  return (
    <InView
      triggerOnce
      threshold={0.2}
    >
      {({ inView, ref }) => (
        <Box
          ref={ref}
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-evenly",
            
            backgroundColor: "#E6E7EE",
            pt: 5,
            pb: 8,
            px: { xs: 2, md: 10 },
            gap: 5,
          }}
        >
          {aboutCardContent.map((item, i) => (
            <NumberCard
              head={item.head}
              icon={item.icon}
              key={i}
              body={item.body}
              boxShadow="inset 2px 2px 5px #b8b9be,inset -3px -3px 7px #fff"
              inView={inView}
              suffix={item.suffix}
            />
          ))}
        </Box>
      )}
    </InView>
  );
};

export default Hero3;
