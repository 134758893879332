import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import about from "../images/about.jpg";
import Seo from "../main/Seo";
const AboutUs = () => {
  return (
    <Box
      sx={{
        bgcolor: "#E6E7EE",
        display: "flex",
        flexDirection: "column",
        color: "#44476a",
      }}
    >
      <Seo
        title="About Us -Oxyfresh"
        description="Know about oxyfresh and its founders"
        name="oxyfresh"
        type="About us page "
      />{" "}
      <Box
        sx={{
          width: "100%",
          backgroundImage: `url(${about})`,
          height: 500,
          backgroundSize: "cover",
          filter: "blur(4px)",
          position: "relative",
        }}
      ></Box>
      <Box
        sx={{
          backgroundColor: "rgba(101,165,191,0.7)",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          px: 5,
          py: 2,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#fff",
            fontWeight: "bold",
            whiteSpace: "nowrap",
          }}
        >
          About Us
        </Typography>
      </Box>
      <Box sx={{ textAlign: "left", p: 5 }}>
        <Typography
          variant="h4"
          sx={{ textAlign: "center", py: 3, color: "#31344b" }}
        >
          {" "}
          Dr. Kannan Rajamani Karnam
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
            gap: 5,
            textAlign: "left",
            alignItems: { xs: "center", md: "start" },
          }}
        >
          <Box sx={{ flex: 2 }}>
            <Typography variant="body1" sx={{ pb: 3 }}>
              Dr. Kannan Rajamani Karnam was born in Mumbai and graduated from
              Grant Medical College and JJ Hospital, Byculla, Mumbai. Dr. Kannan
              Rajamani Karnam, MBBS began practicing allopathy in January, 1980.
              Within two years, the limitations of this form of medicine struck
              him.
            </Typography>
            <Typography variant="body1" sx={{ pb: 3 }}>
              Though dubbed the best form of treatment, it had no cures for any
              chronic illnesses and was proficient in converting any acute
              illness to a chronic one. His search for alternatives led him to
              learn Homeopathy from the Association of Homeopathy in Mumbai and
              later, Siddha medicine from reknowned Dr. Srilasri GANAPATHY
              SWAMIGAL, Madurai.{" "}
            </Typography>{" "}
            <Typography variant="body1" sx={{ pb: 3 }}>
              He then learnt ozone from the Ozone Forum of India under the able
              guidance of Dr. Parul Saheba. Ozone fascinated him and he
              proceeded to train further in Ozone and Prolozone from world
              renowned ozone specialist Dr. Frank Shallenberger in Nevada State,
              USA.
            </Typography>
            <Typography variant="body1" sx={{ pb: 3 }}>
              {" "}
              He learnt Chelation therapy and Bioidentical Hormone therapy
              whilst in America. He also tutored under Renowned Prolotherapist
              Dr. Margaret Taylor, learning both Prolotherapy and Neural
              Prolotherapy in Australia. His journey also took him to Ukraine
              where he learnt Bio-Resonance Therapy and also was educated to do
              Ozone EBOO therapy under Dr. Nazarov in Odessa, Ukraine. Back home
              he has also done VARMA therapy and ACCUTOUCH therapy.
            </Typography>
            <Typography variant="body1" sx={{ pb: 3 }}>
              {" "}
              His concern for the economically backward has led him to conduct
              medical camps in rural areas in MURBAD, Maharashtra. He has
              received numerous awards (listed below) for his excellent social
              work. He was also involved in POLIO CORRECTIVE SURGERY Camps in
              Kashmir and various parts of India conducted by the Ghatkopar
              Rotary Club, Mumbai lead by Dr. Maheshbhai Parikh who is a
              renowned social worker.{" "}
            </Typography>
            <Typography variant="body1" sx={{ pb: 3 }}>
              {" "}
              He has been involved in free monthly medical camps for the rural
              Adivasi population of Murbad for the past 20 years. He has also
              started free Ozone camps around MURBAD for the Adivasi population
              thus making their lives more productive and fulfilling.
              <br /> He recently started doing PRP Treatment (Platelet rich
              plasma treatment) which gives permanent relief to aching joints,
              back pain, non – healing wounds and cosmetology procedures.
            </Typography>
          </Box>

          <img
            alt="doctor"
            style={{
              width: 300,
              flex: 1,
              borderRadius: "50%",
              padding: "5px",
            }}
            src="https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg?w=740&t=st=1685881283~exp=1685881883~hmac=92e72d07a60df97aaf1d429aae0d0aea4edeedb24ddfaa1ebefc2acd2755c4c8"
          />
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{ textAlign: "center", pb: 3, color: "#31344b" }}
      >
        {" "}
        Dr. Krishna Karnam
      </Typography>
      <Box
        sx={{
          p: 5,
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "center", md: "start" },

          gap: 3,
        }}
      >
        {" "}
        <img
          alt="doctor"
          style={{
            width: 250,

            alignSelf: { xs: "center", md: "default" },

            borderRadius: "50%",
          }}
          src="https://img.freepik.com/free-vector/doctor-character-background_1270-84.jpg?w=740&t=st=1685881283~exp=1685881883~hmac=92e72d07a60df97aaf1d429aae0d0aea4edeedb24ddfaa1ebefc2acd2755c4c8"
        />
        <Box
          sx={{
            display: "flex",
            pt: 3,
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="body1" sx={{ textAlign: "left" }}>
            Dr. K. R. Karnam’s son is as illustrious as his father. A M.B.B.S
            graduate from Loni, Maharashtra, he joined his father as an ozone
            specialist. Tutored under Dr. Parul Saheba in Ozone therapy, He also
            trained in Ozone & Prolozone with Dr. Frank Shallenberger who is a
            world renowned Ozone specialist. He learnt chelation from ACAM
            (American college for advancement in medicine) in Las Vegas. He is
            also a Bio-resonance therapy specialist having trained in Kiev,
            Ukraine. Having also trained under Dr. Nazarov for EBOO and cosmetic
            ozone, he is indeed an accomplished ozone therapist. He has also
            learned prolotherapy from Dr. Margaret Taylor, Australia.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutUs;
