import React from "react";
import UnderConstruction from "../../utils/UnderConstruction";
import { Box } from "@mui/material";
import Seo from "../../main/Seo";

const HydrogenPeroxideTherapy = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE" }}>
      <Seo
        title="Hydrogen Peroxide Therapy -Oxyfresh"
        description="Hydrogen Peroxide  Therapy"
        name="oxyfresh"
        type="Hydrogen Peroxide Therapy page "
      />{" "}
      <UnderConstruction />
      HydrogenPeroxideTherapy
    </Box>
  );
};

export default HydrogenPeroxideTherapy;
