import React from "react";
import Gallery2 from "../components/Gallery2";
import { Box, Divider, Typography } from "@mui/material";
import photo1 from "../images/treat1.jpg";
import photo2 from "../images/treat2.jpg";
import photo3 from "../images/treat3.jpg";
import photo4 from "../images/treat4.jpg";
import photo5 from "../images/treat5.jpg";
import photo6 from "../images/treat6.jpg";
import photo7 from "../images/treat7.jpg";
import photo8 from "../images/treat8.jpg";
import photo9 from "../images/treat9.jpg";
import photo10 from "../images/treat10.jpg";
import Seo from "../main/Seo";

const Gallery3 = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        bgcolor: "#E6E7EE",
        p: { xs: 1, md: 5 },
        color: "#31344b",
      }}
    >
      <Seo
        title="Gallery -Oxyfresh"
        description="Photos from our camp and treatment photos"
        name="oxyfresh"
        type="Gallery page "
      />{" "}
      <Typography variant="h3" sx={{ pb: 3 }}>
        {" "}
        Our Camp
      </Typography>
      <Gallery2 />
      <Divider />
      <Typography
        variant="h3"
        sx={{ mt: 7, pb: 3, textDecoration: "underline" }}
      >
        {" "}
        Our Treatment
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 3,
          gap: 5,
          justifyContent: "space-between",
          // alignItems: "space-between",
        }}
      >
        {galleryData.map((item, i) => (
          <img src={item.img} alt={item.title} style={{ width: 300 }} key={i} />
        ))}
      </Box>
    </Box>
  );
};

export default Gallery3;

const galleryData = [
  {
    img: photo1,
    title: "gallery1",
  },
  {
    img: photo2,
    title: "gallery2",
  },
  {
    img: photo3,
    title: "gallery3",
  },
  {
    img: photo4,
    title: "gallery4",
  },
  {
    img: photo5,
    title: "gallery5",
  },
  {
    img: photo6,
    title: "gallery6",
  },
  {
    img: photo7,
    title: "gallery7",
  },
  {
    img: photo8,
    title: "gallery5",
  },
  {
    img: photo9,
    title: "gallery6",
  },
  {
    img: photo10,
    title: "gallery7",
  },
];
