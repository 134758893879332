import React from "react";
import { Container, Box, Paper } from "@mui/material";
import { Typography } from "@mui/material";
import img2 from "../images/img-2.jpg";
import img3 from "../images/img-3.jpg";
import img4 from "../images/img-4.jpg";
import img5 from "../images/img-5.jpg";
import img6 from "../images/img-6.jpg";
import img7 from "../images/img-7.jpg";
import img8 from "../images/img-8.jpg";
import img9 from "../images/img-9.jpg";
import img10 from "../images/img-10.jpg";

const Gallery = () => {
  const images = [
    {
      src: "https://img.freepik.com/premium-vector/medical-consultaion-concept_118813-3276.jpg?w=740",
      width: 40,
      height: 100,
    },
    {
      src: img2,
      width: 40,
      height: 100,
    },
    {
      src: img3,
    },
    {
      src: img4,
    },
    {
      src: img5,
    },
    {
      src: img6,
    },
    {
      src: img7,
    },
    {
      src: img8,
    },
    {
      src: img9,
    },
    {
      src: img10,
    },

    {
      src: "https://images.unsplash.com/photo-1612537785055-e226dae15987?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80",
      width: 40,
      height: 100,
    },
    {
      src: "https://img.freepik.com/free-vector/doctor-checking-patient_23-2147795898.jpg?w=2000",
      width: 70,
      height: 100,
    },

    {
      src: "https://img.freepik.com/free-vector/doctor-examining-patient-clinic-illustrated_23-2148856559.jpg?size=626&ext=jpg&ga=GA1.1.2136575466.1684650788&semt=ais",
      width: 70,
      height: 100,
    },
  ];
  return (
    <Box sx={{ backgroundColor: "#E6E7EE", p: 5 }}>
      <Container>
        <Typography variant="h4" align="center" gutterBottom>
          Gallery
        </Typography>

        <Box sx={{ display: "flex", overflowX: "auto", padding: "16px" }}>
          {images.map((imageUrl, index) => (
            <Box key={index} sx={{ flex: "0 0 auto", marginRight: "16px" }}>
              <Paper sx={{ width: "200px", height: "200px" }}>
                <img
                  src={imageUrl.src}
                  alt={`gallery ${index + 1}`}
                  style={{ width: "100%", height: "100%" }}
                />
              </Paper>
            </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default Gallery;
