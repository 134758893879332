import React from "react";
import Navbar from "../main/navbar/NavBar";
import { customTheme } from "../utils/Theme";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Outlet } from "react-router-dom";
import Footer from "../main/Footer";
import Box from "@mui/material/Box";
import ScrollToTop from "../main/ScrollToTop";
import TopNav from "../components/TopNav";

const Root = () => {
  const theme = createTheme(customTheme("light"));

  return (
    <Box style={{ textAlign: "center" }}>
      <ThemeProvider theme={theme}>
        <ScrollToTop>
          <Box>
            <TopNav />
            <Navbar />
            <Outlet />
          </Box>
        </ScrollToTop>

        <Footer />
      </ThemeProvider>
    </Box>
  );
};

export default Root;
