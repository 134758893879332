import { Box, Typography } from "@mui/material";
import React from "react";
import TeamCard from "../../utils/TeamCard";
import hello from "../../images/hello.png";
import { InView } from "react-intersection-observer";
import Grow from "@mui/material/Grow";
import doc1 from "../../images/doc-1.png";
import doc2 from "../../images/doc-2.png";
import doc3 from "../../images/doc-3.png";

const Hero2 = () => {
  const acmvCardContent = [
    {
      head: "Dr. Name",
      body: " Our certified technicians are trained to install all types and brands of air conditioning systems. From split systems to ducted systems, we have the expertise to handle any installation job with precision and care. We follow industry standards and best practices to ensure that your air conditioning system is installed correctly for optimal performance and energy efficiency.",

      img: doc1,
    },
    {
      head: "Dr. Name",
      body: "  Regular maintenance is proven to improve the operation & efficiency of the equipment, is a legal requirment ( for certain equipment ) and helps prevent operational issues, saving you both time & money.  We look forward to discussing your maintenances requirements & treating you to the DMH Experience !",

      img: doc2,
    },

    {
      head: "Dr. Name",
      body: " We believe in providing transparent and competitive pricing for our aircon installation services. Our team will provide you with a detailed quote that includes all costs upfront, so you know exactly what to expect. We strive to offer affordable options without compromising on quality or service.",

      img: doc3,
    },
  ];
  return (
    <Box
      sx={{
        textAlign: "left",
        p: { xs: 1, md: 5 },
        backgroundColor: "#E6E7EE",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          p: 5,
          gap: 5,
        }}
      >
        <Box>
          {" "}
          <img src={hello} alt="welcome" />
        </Box>
        <Box sx={{ fontFamily: "Nunito Sans, sans-serif", color: "#44476a" }}>
          <Typography
            variant="h4"
            sx={{ textAlign: "center", py: 5, color: "#31344b" }}
          >
            WELCOME TO OUR MEDICAL CENTER
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            Dr. Kannan Rajamani Karnam Born in Mumbai and having graduated from
            Grant Medical College and JJ Hospital, Byculla, Mumbai. Dr. Kannan
            Rajamani Karnam, MBBS began practicing allopathy in Jan 1980. Within
            two years, the limitations of this form of medicine struck him.
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            {" "}
            Though dubbed the best form of treatment, it had no cures for any
            chronic illnesses and was proficient in converting any acute illness
            to a chronic one. His search for alternates led him to learn
            homeopathy from the association of homeopathy in Mumbai and Siddha
            medicine from reknowned Dr. Srilasri GANAPATHY SWAMIGAL, Madurai.
          </Typography>
          <Typography variant="body1" sx={{ pb: 2 }}>
            {" "}
            He then learnt ozone from the ozone forum of India under the able
            guidance of Dr. ParulSaheba. Ozone fascinated him and he proceeded
            to train further in Ozone and Prolozone from world reknowned ozone
            specialist Dr. Frank Shallenberger in Nevada State, USA.
          </Typography>
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          py: 5,
          fontWeight: "bold",
          color: "#31344b",
        }}
      >
        Our Team
      </Typography>
      <InView
        triggerOnce
        //  rootMargin={"-200px"}
        threshold={0.3}
      >
        {({ inView, ref }) => (
          <Box ref={ref}>
            <Grow
              in={inView}
              style={{ transformOrigin: "0 0 0" }}
              {...(inView ? { timeout: 2000 } : {})}
            >
              <Box
                sx={{
                  // backgroundImage: `linear-gradient(to top, #f3f0f1 0%, #76777C 100%)`,
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  justifyContent: "space-evenly",
                  p: { xs: 2, md: 5 },
                  gap: 2,
                  flexWrap: "wrap",
                }}
              >
                {acmvCardContent.map((item, i) => (
                  <TeamCard
                    head={item.head}
                    key={i}
                    i={i}
                    body={item.body}
                    img={item.img}
                  />
                ))}{" "}
              </Box>
            </Grow>
          </Box>
        )}
      </InView>
    </Box>
  );
};

export default Hero2;
