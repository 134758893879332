import React from "react";
import underconstruction from "../images/undercon.png";
const UnderConstruction = () => {
  return (
    <div>
      <img
        src={underconstruction}
        alt="Under Construction"
        style={{ width: 300 }}
      />
    </div>
  );
};

export default UnderConstruction;
