import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import UnderConstruction from "../utils/UnderConstruction";
import Divider from "@mui/material/Divider";
import testimonial from "../images/testimonials.png";
import Seo from "../main/Seo";

const Testimonials = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE", color: "#44476a" }}>
      <Seo
        title="Testimonials -Oxyfresh"
        description="testimonials from our customers"
        name="oxyfresh"
        type="Testimonials page "
      />{" "}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", md: "row" },
          color: "#44476a",
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",

            alignItems: "center",
          }}
        >
          {" "}
          <Typography
            variant="h3"
            sx={{
              color: "#31344b",
              fontWeight: "bold",
              mx: "auto",
            }}
          >
            Testimonials
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", md: "50%" } }}>
          <img
            src={testimonial}
            alt="contact"
            style={{ flex: 1, width: "100%" }}
          />
        </Box>
      </Box>
      <Box sx={{ pt: 10 }}>
        {" "}
        <Typography variant="h3" sx={{ color: "#31344b" }}>
          Customer Testimonials
        </Typography>
        <Box sx={{ p: 5 }}>
          <Typography
            variant="h4"
            sx={{ color: "#31344b", textDecoration: "underline" }}
          >
            Testimonial 1
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 2 }}>
            Born in Mumbai and having graduated from Grant Medical College and
            JJ Hospital, Byculla, Mumbai. Dr. Kannan RajamaniKarnam, MBBS began
            practicing allopathy in Jan 1980. Within two years, the limitations
            of this form of medicine struck him. Though dubbed the best form of
            treatment, it had no cures for any chronic illnesses and was
            proficient in converting any acute illness to a chronic one. His
            search for alternates led him to learn homeopathy from the
            association of homeopathy in Mumbai and Siddha medicine from
            reknowned Dr. Srilasri GANAPATHY SWAMIGAL, Madurai.
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 5 }}>
          <Typography
            variant="h5"
            sx={{ color: "#31344b", textDecoration: "underline" }}
          >
            Testimonial 2
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 2 }}>
            Though dubbed the best form of treatment, it had no cures for any
            chronic illnesses and was proficient in converting any acute illness
            to a chronic one. His search for alternates led him to learn
            homeopathy from the association of homeopathy in Mumbai and Siddha
            medicine from reknowned Dr. Srilasri GANAPATHY SWAMIGAL, Madurai.
            Born in Mumbai and having graduated from Grant Medical College and
            JJ Hospital, Byculla, Mumbai. Dr. Kannan RajamaniKarnam, MBBS began
            practicing allopathy in Jan 1980. Within two years, the limitations
            of this form of medicine struck him.
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ p: 5 }}>
          <Typography
            variant="h5"
            sx={{ color: "#31344b", textDecoration: "underline" }}
          >
            Testimonial 3
          </Typography>
          <Typography variant="body1" sx={{ textAlign: "left", p: 2 }}>
            Though dubbed the best form of treatment, it had no cures for any
            chronic illnesses and was proficient in converting any acute illness
            to a chronic one. His search for alternates led him to learn
            homeopathy from the association of homeopathy in Mumbai and Siddha
            medicine from reknowned Dr. Srilasri GANAPATHY SWAMIGAL, Madurai.
          </Typography>
        </Box>
      </Box>
      <Typography variant="h3">Video Testimonials</Typography>
      <UnderConstruction />
    </Box>
  );
};

export default Testimonials;
