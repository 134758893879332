import React from "react";
import { Box, Button } from "@mui/material";

import EmailIcon from "@mui/icons-material/Email";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
const TopNav = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        px: 5,
        pt: 2,
        gap: { xs: 1, md: 5 },
        backgroundColor: "#E6E7EE",
      }}
    >
      <Button
        variant="contained"
        href="tel:+919860385320"
        startIcon={<PhoneInTalkIcon />}
        sx={{ mr: 1 }}
      >
        +91 98603 85320
      </Button>
      <Button
        variant="contained"
        href="mailto:oxy4sh@gmail.com"
        // target="_blank"
        startIcon={<EmailIcon />}
        sx={{ mr: 1 }}
      >
        oxy4sh@gmail.com
      </Button>

      <Button
        variant="contained"
        endIcon={<BookOnlineIcon />}
        sx={{ ml: { xs: 0, md: "auto" }, mr: 1 }}
      >
        Book Appointment
      </Button>
    </Box>
  );
};

export default TopNav;
