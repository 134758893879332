import React from "react";
import UnderConstruction from "../../utils/UnderConstruction";
import { Box } from "@mui/material";
import Seo from "../../main/Seo";

const BodyAlkalinizingTreatmentsForCancer = () => {
  return (
    <Box sx={{ bgcolor: "#E6E7EE" }}>
      <Seo
        title="Body Alkalinizing Treatments For Cancer -Oxyfresh"
        description="Body Alkalinizing Treatments For Cancer"
        name="oxyfresh"
        type="Body Alkalinizing Treatments For Cancer page "
      />{" "}
      <UnderConstruction />
      BodyAlkalinizingTreatmentsForCancer
    </Box>
  );
};

export default BodyAlkalinizingTreatmentsForCancer;
